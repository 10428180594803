export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    label: 'field.name',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'description',
    label: 'field.description',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
  },
];
